import { useEffect, useState } from "react";
import {
  DBDataType,
  DBSensorType,
  HourDataType,
  MyDispDataType,
  SensorType,
  ZoneType,
} from "../../dataTypes/myDataTypes";

import _ from "lodash";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

import { sensors } from "../../configs/Tags";
import "../../index.css";
import { LineChart, LineSeriesType, MarkElement, MarkElementProps } from "@mui/x-charts";
import { getSensorDispDataAvg } from "./dataUtils";
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import GridOffTwoToneIcon from '@mui/icons-material/GridOffTwoTone';

import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone';
import ShowChartTwoToneIcon from '@mui/icons-material/ShowChartTwoTone';
//========================================================================================================================
interface MyDataRowProps {
  zoneName: string;
  selectedSensorNames: string[];
  dispData: MyDispDataType;
  showTime: boolean;
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyDataRow({
  zoneName,
  selectedSensorNames,
  dispData,
  showTime
}: MyDataRowProps) {
  let zoneSensors: DBSensorType[] = dispData.DbSensors.filter(
    (dbs) => dbs.zone == zoneName
  );
  let selectedSensors: DBSensorType[] = zoneSensors.filter(
    (s) => selectedSensorNames.indexOf(s.name) != -1
  );
  return (
    <TableRow component="th" scope="row">
      {showTime && <TableCell width={400} align="center">{`${dispData.timeDisp}`}</TableCell>}
      {selectedSensors.map((s) => (
        <TableCell key={`${s.zone}-${s.name}`} align="center" >
          {s.value.toFixed(s.decimal)}
        </TableCell>
      ))}
    </TableRow>
  );
}

//========================================================================================================================
interface MyZoneTableProps {
  dispData: MyDispDataType[];
  selectedSensorNames: string[];
  showTime: boolean;
  zone: ZoneType | undefined;
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyZoneTable({
  dispData,
  selectedSensorNames,
  zone,
  showTime
}: MyZoneTableProps) {
  if (!zone) return (<></>)
  let zoneName = zone.name;
  let zoneSensors = sensors.filter((s) => s.zone == zoneName);
  let mySensors = zoneSensors.filter(
    (zs) => selectedSensorNames.indexOf(zs.name) >= 0
  );
  if (mySensors.length == 0) return <></>;

  let cellWidth = 90;
  let myWidth = (mySensors.length + 1) * cellWidth;
  return (
    <TableContainer
      sx={{ minWidth: myWidth, maxWidth: "100%" }}
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {showTime && <TableCell rowSpan={2}>Time</TableCell>}
            <TableCell colSpan={mySensors.length} align="center" >
              {zone.description}
            </TableCell>
          </TableRow>

          <TableRow>
            {mySensors.map((sn) => (
              <TableCell align="center" key={`${sn.zone}-${sn.name}`} width={200}>
                {sn.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {_.uniqBy(dispData, "timeDisp").map((d) => (
            <MyDataRow
              key={`${d.timeDisp}`}
              selectedSensorNames={selectedSensorNames}
              zoneName={zoneName}
              dispData={d}
              showTime={showTime}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            {showTime && <TableCell align="center">
              <b>Average</b>
            </TableCell>}
            {mySensors.map((s) => (
              <TableCell align="center" key={`${s.zone}-${s.name}`}>
                <b>{getSensorDispDataAvg(s, dispData).toFixed(s.decimal)}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
//========================================================================================================================
interface MyGraphProps {
  dispData: MyDispDataType[];
  selectedZones: ZoneType[];
  selectedSensorNames: string[];
  
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyGraph({
  dispData,
  selectedZones,
  selectedSensorNames,
}: MyGraphProps) {
  const[showGrid,setShowGrid] = useState(false)  
  const[showMark,setShowMark] = useState(false)  
  let mySensors = sensors.filter(
    (s) =>
      selectedZones.filter(z => z.name == s.zone).length >= 1 &&
      selectedSensorNames.indexOf(s.name) >= 0
  );

  let xLabels: string[] = [];
  let mySeries: LineSeriesType[] = [];
  let myYAxis: any[] = [];

  const slotsMark = (props: MarkElementProps) => {

    return (
        <MarkElement {...props} classes={{root: "customMarkElement"}} />
    )
}

  mySensors.forEach((sensor) => {
    // let zoneName:string = sensor.zone;
    // let zone:ZoneType|undefined = selectedZones.find(z=>z.name == zoneName) 
    // if(zone)
    //   zoneName = zone.description;

     
    mySeries = [
      ...mySeries,
      {
        type: "line",
        label: `${sensor.zone}-${sensor.name}`,
        data: [],
        yAxisId: sensor.name,
        showMark: showMark,
        
      },
    ];
    myYAxis = [
      ...myYAxis,
      {
        id: sensor.name,
        min: sensor.minVal,
        max: sensor.maxVal,
        scaleType: "linear",
      },
    ];
  });

  dispData.forEach((record) => {
    xLabels.push(record.timeDisp);
    record.DbSensors.forEach((dbSensor) => {
      let series: LineSeriesType | undefined = mySeries.find(
        (s) => s.label == `${dbSensor.zone}-${dbSensor.name}`
      );
      if (series && series.data) {
        series.data.push(dbSensor.value);
      }
    });
  });

  return (
    <div>
      <div>
        <IconButton
          color="primary"
          onClick={()=>{setShowGrid(!showGrid)}}
        >
          {showGrid?<GridOffTwoToneIcon/>:<GridOnTwoToneIcon/>}
        </IconButton>
        <IconButton
          color="primary"
          onClick={()=>{setShowMark(!showMark)}}
        >
          {showMark?<ShowChartTwoToneIcon/>:<TimelineTwoToneIcon/>}

        </IconButton>

      </div>
      <LineChart
         slots={{
          mark: slotsMark,
      }}
        sx={{
            "& .customMarkElement": {
                scale: "0.5",
            }
        }}
      
        xAxis={[{ scaleType: "point", data: xLabels }]}
        yAxis={myYAxis}
        series={mySeries}
        leftAxis={myYAxis[0]}
        width={1400}
        height={800}
        grid={{ vertical: showGrid, horizontal: showGrid }}
      />
    </div>
  );
}
//========================================================================================================================
interface MyDataDispProps {
  dispData: MyDispDataType[];
  selectedZones: ZoneType[];
  selectedSensorNames: string[];
  selectedTypeDisp: string;
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyDataDisp({
  dispData,
  selectedZones,
  selectedSensorNames,
  selectedTypeDisp,
}: MyDataDispProps) {
  return (
    <div
      style={{
        margin: 5,
        maxHeight: "1070px",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 2,
        }}
      >
        {selectedTypeDisp == "table" &&
          selectedZones.map((zn) => (
            <MyZoneTable
              key={zn.id}
              zone={zn}
              selectedSensorNames={selectedSensorNames}
              dispData={dispData}
              showTime={selectedZones.indexOf(zn) == 0}
            />
          ))}

        {selectedTypeDisp == "graph" && (
          <MyGraph
            dispData={dispData}
            selectedSensorNames={selectedSensorNames}
            selectedZones={selectedZones}
          />
        )}
      </div>
    </div>
  );
}
//========================================================================================================================
