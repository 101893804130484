
import { Checkbox } from "@mui/material";
import { useState } from "react";

//========================================================================================================================

interface MyListViewProps {
  title: string;
  items: string[];

  setSelectedItem: (item: string | undefined) => void;
  selectedItem: string | undefined;
}
//------------------------------------------------------------------------------------------------------------------------
export function MyListView({
  title,
  items,
  selectedItem,
  setSelectedItem,
}: MyListViewProps) {
  // const [selectedItem,setSelectedItem] = useState<undefined|string>(selectedItem);

  return (
    <div
      style={{
        background: "white",
        borderRadius: "20px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "rgb(65, 169, 255)",
        margin: 5,
        padding: 2.1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div>{title}</div>
        <div>
          <b>{selectedItem}</b>
        </div>
      </div>
      <ul>
        {items.map((item) => (
          <li
            key={item}
            onClick={() => {
              setSelectedItem(item);
              // onItemSelect(item);
            }}
            style={{
              background:
                selectedItem == item ? "rgb(65, 169, 255)" : "#ced8ff",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
              display: "flex",
              margin: "10px 10px",
              fontSize: "15px",
              padding: "2px 20px",
              border: "rgb(65, 169, 255)",
              borderRadius: "20px",
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
//========================================================================================================================
interface MyEnhancedListViewProps {
  title: string;
  items: string[];

  setSelectedItems: (items: string[]) => void;
  selectedItems: string[];
}
//------------------------------------------------------------------------------------------------------------------------
export function MyEnhancedListView({
  title,
  items,
  selectedItems,
  setSelectedItems,
}: MyEnhancedListViewProps) {
  // const [selectedItem,setSelectedItem] = useState<undefined|string>(selectedItem);

  return (
    <div
      style={{
        background: "white",
        borderRadius: "20px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "rgb(65, 169, 255)",
        margin: 5,
        padding: 2.1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Checkbox
            checked={selectedItems.length == items.length}
            onChange={(e) => {
              if (e.target.checked)
                setSelectedItems([...items])
              else
                setSelectedItems([])

            }}
          />
          {title}

        </div>
      </div>
      <ul>
        {items.map((item) => (
          <li
            key={item}
            onClick={() => {
              let indx: number = selectedItems.indexOf(item);
              if (indx == -1)
                setSelectedItems([...selectedItems, item])
              else
                setSelectedItems([...selectedItems.filter(itm => itm !== item)]);

            }}
            style={{
              background:
                selectedItems.indexOf(item) != -1 ? "rgb(65, 169, 255)" : "#ced8ff",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
              display: "flex",
              margin: "10px 10px",
              fontSize: "15px",
              padding: "2px 20px",
              border: "rgb(65, 169, 255)",
              borderRadius: "20px",
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
//========================================================================================================================
