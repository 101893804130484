import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DBDataType, HourDataType, MyDateType, UserType } from "../../dataTypes/myDataTypes";
import { useEffect, useState } from "react";
import { getPrevDate, MyCompareDates } from "./dataUtils";
import Slider from '@mui/material/Slider';
import _, { indexOf } from "lodash";
import { MyTimePicker, timeDataArr } from "./mainDBPage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import serviceData from "../../services/dataService"
//========================================================================================================================
export function getRangeData(
  d1: MyDateType,
  d2: MyDateType,
  allData: DBDataType[]
): DBDataType[] {
  if (!(d1 && d2)) return [];
  if (MyCompareDates(d1, d2, "==")) {
    d1.H = 0;
    d2.H = 23;
  }
  let timeData: DBDataType[] = [...allData.filter((d) => MyCompareDates(d.MyDate, d1, ">=") && MyCompareDates(d.MyDate, d2, "<="))];
  // let daysData: DBDataType[] = _.uniqBy(timeData, 'day');
  // daysData.forEach(d => {
  //   let dayHours: DBDataType[] = _.uniqBy(timeData.filter(td => (td.day == d.day)), 'hour');
  // });
  return (timeData)
}
//========================================================================================================================
interface DBTimeRangePickerProps {
  allData: DBDataType[];
  onChange: (d: DBDataType[]) => void;
}
//------------------------------------------------------------------------------------------------------------------------

export function DBTimeRangePicker({ allData, onChange }: DBTimeRangePickerProps) {
  let _date = new Date(Date.now());
  let todayDate: MyDateType = {
    D: _date.getDate(),
    M: _date.getMonth() + 1,
    Y: _date.getFullYear(),
    H: _date.getHours(),
    m: 0,
  };
  const [selctedTime, setSelectedTime] = useState<string | undefined>("Today");
  const [d1, setD1] = useState<MyDateType | undefined>(todayDate);
  const [d2, setD2] = useState<MyDateType | undefined>(todayDate);
  useEffect(() => {
    if (d1 && d2) onChange([...getRangeData(d1, d2, allData)]);
  }, [d1, d2]);

  useEffect(() => {
    setSelectedTime("Today")
  }, [allData]);

  return (
    <div
      style={{
        margin: "5px",
        padding: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgb(65, 169, 255)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <Select
        style={{
          margin: "10px",
          width: "100%",
        }}
        value={selctedTime ? selctedTime : ""}
        onChange={(e) => {
          let newTime = e.target.value;
          setSelectedTime(newTime);
          if (newTime == "Today") {
            let d1 = { ...todayDate };
            d1.H = 0;
            setD1({ ...d1 });
            setD2({ ...todayDate });
          } else if (newTime == "Yesterday") {
            let d1: MyDateType = getPrevDate(todayDate, 1);
            let d2: MyDateType = { ...d1 };
            d1.H = 0;
            d2.H = 23;
            setD1(d1);
            setD2(d2);
          } else if (newTime == "Last 7 Days") {
            let d1: MyDateType = getPrevDate(todayDate, 7);
            d1.H = 0;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Last 30 Days") {
            let d1: MyDateType = getPrevDate(todayDate, 30);
            d1.H = 0;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "This Month") {
            let d1: MyDateType = {
              D: 1,
              M: todayDate.M,
              Y: todayDate.Y,
              H: 0,
              m: 0,
            };
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "This Year") {
            let d1: MyDateType = { D: 1, M: 1, Y: todayDate.Y, H: 0, m: 0 };
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Last 24 hours") {
            let d1: MyDateType = getPrevDate(todayDate, 1);
            d1.H = todayDate.H;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Custom") {
            setD1(undefined);
            setD2(undefined);
          }
        }}
      >
        {timeDataArr.map((d) => (
          <MenuItem key={d} value={d}>
            <b>{d}</b>
          </MenuItem>
        ))}
      </Select>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue: Dayjs | null) => {
              if (!newValue) setD1(undefined);
              else
                setD1({
                  D: newValue.date(),
                  M: newValue.month() + 1,
                  Y: newValue.year(),
                  H: 0,
                  m: 0,
                });
            }}
            value={d1 ? dayjs(`${d1.Y}-${d1.M}-${d1.D}`) : dayjs(``)}
            disabled={selctedTime ? selctedTime != "Custom" : false}
          />
        </LocalizationProvider>
        <span>-</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue: Dayjs | null) => {
              if (!newValue) setD2(undefined);
              else
                setD2({
                  D: newValue.date(),
                  M: newValue.month() + 1,
                  Y: newValue.year(),
                  H: 0,
                  m: 0,
                });
            }}
            value={d2 ? dayjs(`${d2.Y}-${d2.M}-${d2.D}`) : dayjs(``)}
            disabled={selctedTime ? selctedTime != "Custom" : false}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
//========================================================================================================================
interface AdminDBControlBoardProps {
  allData: DBDataType[];
  dispData: DBDataType[];
  setDispData: (d: DBDataType[]) => void;
}
//------------------------------------------------------------------------------------------------------------------------
export function AdminDBControlBoard({
  allData,
  dispData,
  setDispData,
}: AdminDBControlBoardProps) {
  useEffect(() => {
    let _date = new Date(Date.now());
    let todayDate1: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 0,
      m: 0,
    };
    let todayDate2: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 23,
      m: 0,
    };
    setDispData(getRangeData(todayDate1, todayDate2, allData));
  }, [allData]);

  return (
    <div
      style={{
        borderColor: "rgb(65, 169, 255)",
        display: "flex",
        flexDirection: "column",
        minWidth: '300px',
        maxWidth: '300px',
      }}
    >

      <DBTimeRangePicker
        allData={allData}
        onChange={(d) => {
          if (d.length == 0) {
            setDispData([]);
          }
          setDispData(d);
        }}
      />


    </div>
  );
}
//========================================================================================================================
interface DBDataTableProps {
  dispData: DBDataType[];
  onDeleteData: () => void;
  user: UserType

}
//------------------------------------------------------------------------------------------------------------------------
export function DBDataTable({ dispData, onDeleteData, user }: DBDataTableProps) {
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [pageData, setPageData] = useState<DBDataType[]>(dispData.slice(0, pageSize))
  const [pagesCnt, setPagesCnt] = useState(Math.ceil(dispData.length / 10))
  const [selectedData, setSelectedData] = useState<DBDataType[]>([])
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)



  let checkedCnt = 0;

  pageData.forEach(d => {
    if (d.checked) checkedCnt += 1
  })

  function selectionChanged() {
    let sData: DBDataType[] = []
    pageData.forEach(d => {
      if (d.checked)
        sData = [...sData, d]
    })
    setSelectedData([...sData])
  }

  useEffect(() => {
    dispData.forEach(d => d.checked = false)
    setPageData(dispData.slice((page * pageSize) - pageSize, page * pageSize))
    setPagesCnt(Math.ceil(dispData.length / pageSize))
  }, [page, pageSize, dispData])
  return (
    <div
      style={{

      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'

        }}
      >
        <span>{`Page Size ${pageSize}`}</span>
        <Slider
          style={{ width: 100 }}
          value={pageSize}
          onChange={(event: Event, newValue: number | number[]) => {
            let v = newValue as number;
            if (v > 0)
              setPageSize(newValue as number)
          }}

        />
        <Pagination
          style={{ width: '100%' }}
          color="primary"
          count={pagesCnt}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value)
          }
          }
        />

        <IconButton
          color="error"
          disabled={selectedData.length == 0}
          onClick={() => setShowConfirmDelete(true)}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
      </div>


      <Table size="small" aria-label="a dense table" >
        <TableHead>
          <TableRow>
            <TableCell ><b>id</b></TableCell>
            <TableCell ><b>Date</b></TableCell>
            <TableCell ><b>Time</b></TableCell>
            <TableCell >
              <Checkbox
                checked={checkedCnt == pageData.length}
                onChange={(e) => {
                  pageData.forEach(pd => pd.checked = e.target.checked)
                  selectionChanged()
                }}
              />
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {pageData.map((d) => (
            <TableRow key={d.id}>
              <TableCell >{d.id}</TableCell>
              <TableCell >{d.date}</TableCell>
              <TableCell >{d.hourMinute}</TableCell>
              <TableCell >
                <Checkbox
                  checked={d.checked}
                  onChange={
                    (e) => {
                      d.checked = e.target.checked
                      selectionChanged()
                    }}
                />
              </TableCell>
            </TableRow>)
          )}
        </TableBody>
      </Table>

      <Dialog open={showConfirmDelete}>
        <DialogTitle style={{ cursor: "move" }}>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${selectedData.length} records will be deleted permenantly`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={async () => {
              let d = await serviceData.deleteData(user, selectedData);
              alert(d)
              setShowConfirmDelete(false);
              onDeleteData();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}
//========================================================================================================================
interface AdminDBPageProps {
  allData: DBDataType[]
  onDeleteData: () => void
  user: UserType
}
//------------------------------------------------------------------------------------------------------------------------
export function AdminDBPage({ allData, onDeleteData, user }: AdminDBPageProps) {
  const [dispData, setDispData] = useState<DBDataType[]>([...allData]);
  useEffect(() => {
    let _date = new Date(Date.now());
    let todayDate1: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 0,
      m: 0,
    };
    let todayDate2: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 23,
      m: 0,
    };
    setDispData(getRangeData(todayDate1, todayDate2, allData));
  }, [allData])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <AdminDBControlBoard
        allData={allData}
        dispData={dispData}
        setDispData={setDispData}

      />
      <DBDataTable dispData={dispData} onDeleteData={onDeleteData} user={user} />
    </div>
  );
}
//========================================================================================================================