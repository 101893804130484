import { useEffect, useState } from "react";
import {
  DBDataType,
  DBUserType,
  UserType,
  ZoneType,
} from "../dataTypes/myDataTypes";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EditZonesPage } from "./zones";
import serviceData from "../services/dataService";
import { AdminDBPage } from "./DataBase/adminDbPage";

//=================================================================================================================
interface UserEditDivProps {
  loggedUser: UserType;
  user: DBUserType;
  onUpdate: () => void;
}
//-----------------------------------------------------------------------------------------------------------------
export function UserEditDiv({ loggedUser, user, onUpdate }: UserEditDivProps) {
  const [userName, setUserName] = useState(user.username);
  const [userPassword, setUserPassword] = useState(user.password);
  const [userEmail, setUserEmail] = useState(user.email);
  const [userAuth, setUserAuth] = useState(user.authorization);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  console.log("loggedUser", loggedUser);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TextField
        label="User Name"
        style={{ margin: 4 }}
        value={userName}
        onChange={(e) => {
          setUserName(e.target.value);
          if (e.target.value.length > 5) setShowAlert(false);
        }}
      />

      <TextField
        label="Password"
        style={{ margin: 4 }}
        value={userPassword}
        onChange={(e) => {
          setUserPassword(e.target.value);
        }}
      />

      <TextField
        label="Email"
        style={{ margin: 4 }}
        value={userEmail}
        onChange={(e) => {
          setUserEmail(e.target.value);
        }}
      />
      <Select
        value={userAuth}
        onChange={(e) => {
          setUserAuth(e.target.value);
        }}
        label="Authorization"
      >
        <MenuItem value={"user"}>user</MenuItem>
        {loggedUser.creatorId == "0" && (
          <MenuItem value={"admin"}>admin</MenuItem>
        )}
      </Select>

      <Button
        style={{
          background: "rgb(65, 169, 255)",
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "row",
          maxHeight: "70%",
          // width: "300px",
          margin: 10,
          padding: "1px 15px",
          border: "#ced8ff",
          borderRadius: "20px",
        }}
        onClick={async () => {
          if (userName.length < 5) {
            setShowAlert(true);
            return;
          }
          user.username = userName;
          user.authorization = userAuth;
          user.password = userPassword;
          user.email = userEmail;
          if (user.id == "0") await serviceData.addUser(user);
          else await serviceData.updateUser(user);
          onUpdate();
        }}
      >
        Save
      </Button>

      <Button
        style={{
          background: "rgb(255, 65, 65)",
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "row",
          maxHeight: "70%",
          // width: "300px",
          margin: 10,
          padding: "1px 15px",
          border: "#ced8ff",
          borderRadius: "20px",
        }}
        color="error"
        onClick={async () => {
          setConfirmDelete(true);
        }}
      >
        Delete
      </Button>

      {showAlert && (
        <Alert severity="error">
          User Name length Should be greater than 5 characters
        </Alert>
      )}

      <Dialog open={confirmDelete}>
        <DialogTitle style={{ cursor: "move" }}>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`User ${user.username}  will be deleted permenantly`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={async () => {
              await serviceData.deleteUser(user);
              onUpdate();
              setConfirmDelete(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
//=================================================================================================================
interface UsersPageProps {
  user: UserType;
  users: DBUserType[];
  onUpdate: () => void;
}
//-----------------------------------------------------------------------------------------------------------------
export function UsersPage({ user, users, onUpdate }: UsersPageProps) {
  const [myUsers, setMyUsers] = useState([...users]);
  useEffect(() => {
    setMyUsers([...users]);
  }, [users]);
  return (
    <div>
      <div>
        <Button
          style={{
            margin: 2,
            background: "rgb(65, 169, 255)",
            color: "rgb(255, 255, 255)",
            alignItems: "center",
            justifyContent: "space-around",
            // display: "flex",
            maxHeight: "100%",
            width: "140px",
            // margin: 10,
            padding: "1px 15px",
            border: "#ced8ff",
            borderRadius: "20px",
          }}
          onClick={() => {
            setMyUsers([
              {
                username: "",
                id: "0",
                password: "",
                email: "",
                authorization: "user",
                companyname: user.companyname,
                creatorId: user.id,
              },
              ...users,
            ]);
          }}
        >
          Add User
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {myUsers.map((u) => (
          <UserEditDiv
            key={u.id}
            loggedUser={user}
            user={u}
            onUpdate={onUpdate}
          />
        ))}
      </div>
    </div>
  );
}
//=================================================================================================================

interface ManagementPageProps {
  user: UserType;
  zones: ZoneType[];
  allData: DBDataType[];
  onDeleteData: () => void;
}
//-----------------------------------------------------------------------------------------------------------------
export function ManagementPage({
  user,
  zones,
  allData,
  onDeleteData,
}: ManagementPageProps) {
  const [dispPage, setDispPage] = useState(0);

  const [myUsers, setMyUsers] = useState<DBUserType[]>([]);
  const [updating, setUpdating] = useState(false);

  async function update() {
    setUpdating(true);
    let _myUsers: any[] = await serviceData.getUsers(user.id);
    setMyUsers([..._myUsers]);
    setUpdating(false);
  }
  useEffect(() => {
    update();
  }, []);

  return (
    <div>
      <div
        style={{
          background: "rgb(65, 169, 255)",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "row",
          maxHeight: "70%",
          width: "500px",
          margin: 10,
          padding: "1px 15px",
          border: "#ced8ff",
          borderRadius: "20px",
        }}
      >
        <Button
          style={{
            margin: 2,
            background: "rgb(65, 169, 255)",
            alignItems: "center",
            justifyContent: "space-around",
            display: "flex",
            maxHeight: "100%",
            width: "140px",
            color: "rgb(255, 255, 255)",
            fontWeight: "bold",
            padding: "1px 15px",
            border: "#ced8ff",
            borderRadius: "20px",
          }}
          onClick={() => {
            setDispPage(0);
          }}
          variant={dispPage == 0 ? "contained" : "outlined"}
        >
          Edit Zones
        </Button>

        <Button
          style={{
            margin: 2,
            background: "rgb(65, 169, 255)",
            color: "rgb(255, 255, 255)",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "space-around",
            display: "flex",
            maxHeight: "100%",
            width: "140px",
            // margin: 10,
            padding: "1px 15px",
            border: "#ced8ff",
            borderRadius: "20px",
          }}
          onClick={() => {
            setDispPage(1);
          }}
          variant={dispPage == 1 ? "contained" : "outlined"}
        >
          {updating ? "Updating...." : "Edit Users"}
        </Button>

        <Button
          style={{
            margin: 2,
            background: "rgb(65, 169, 255)",
            color: "rgb(255, 255, 255)",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "space-around",
            display: "flex",
            maxHeight: "100%",
            width: "140px",
            // margin: 10,
            padding: "1px 15px",
            border: "#ced8ff",
            borderRadius: "20px",
          }}
          onClick={() => {
            setDispPage(2);
          }}
          variant={dispPage == 2 ? "contained" : "outlined"}
        >
          {updating ? "Updating...." : "Edit Data"}
        </Button>
      </div>

      <div>
        {dispPage == 0 && <EditZonesPage zones={zones} />}
        {dispPage == 1 && (
          <UsersPage user={user} users={myUsers} onUpdate={() => update()} />
        )}
        {dispPage == 2 && (
          <AdminDBPage
            allData={allData}
            onDeleteData={onDeleteData}
            user={user}
          />
        )}
      </div>
    </div>
  );
}
//=================================================================================================================
