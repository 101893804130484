import { useEffect, useState } from "react";
import {
  DBDataType,
  DBSensorType,
  HourDataType,
  MyDateType,
  MyDispDataType,
  ZoneType,
} from "../../dataTypes/myDataTypes";
import _ from "lodash";
import {
  getDateStr,
  getListOfDataArray,
  getPrevDate,
  getSensorDBAvg,
  getSensorValue,
  getTrimmedTime,
  MyCompareDates,
  sendToExcel,
} from "./dataUtils";
import excelImg from "../../assets/excel-icon.png";
import MenuIcon from "@mui/icons-material/Menu";
import { MyEnhancedListView, MyListView } from "../common/myListView";
import { sensors, zoneNames } from "../../configs/Tags";
import { MyDataDisp } from "./myDBPage";
import { Button, MenuItem, Select } from "@mui/material";
import { NetworkWifi3Bar } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useMediaQuery } from "react-responsive";
export const typeDisplay: string[] = ["table", "graph"];
export const timeDataArr: string[] = [
  "Today",
  "Yesterday",
  "Last 7 Days",
  "Last 30 Days",
  "This Month",
  "This Year",
  "Last 24 hours",
  "Custom",
];
export const detailsDispArr: string[] = [
  "details",
  "hourly",
  "For 3 hours",
  "For 6 hours",
  "For 12 hours",
  "For 24 hours",
];

//========================================================================================================================
export function getHourData(
  d1: MyDateType,
  d2: MyDateType,
  allData: DBDataType[]
): HourDataType[] {
  if (!(d1 && d2)) return [];

  if (MyCompareDates(d1, d2, "==")) {
    d1.H = 0;
    d2.H = 23;
  }
  let timeData: DBDataType[] = [...allData.filter((d) => MyCompareDates(d.MyDate, d1, ">=") && MyCompareDates(d.MyDate, d2, "<="))];
  let hData: HourDataType[] = [];
  let daysData: DBDataType[] = _.uniqBy(timeData, 'day');
  daysData.forEach(d => {
    let dayHours: DBDataType[] = _.uniqBy(timeData.filter(td => (td.day == d.day)), 'hour');
    dayHours.forEach(dh => {
      let hD: HourDataType = {
        date: { ...dh.MyDate },
        dbDataPoints: timeData.filter(td => ((td.date == dh.date) && td.hour == dh.hour)),
        dbSensors: []
      }
      sensors.forEach(sensor => {
        let sensorDB: DBSensorType = {
          name: sensor.name,
          zone: sensor.zone,
          decimal: sensor.decimal,
          value: getSensorDBAvg(sensor, hD.dbDataPoints),
        }
        hD.dbSensors.push(sensorDB)
      })
      hData.push(hD)
    })
  });

  return hData;
}
//========================================================================================================================
export function getDispData(
  hArr: HourDataType[],
  selectedDetailsDisp: string,
): MyDispDataType[] {

  let dispDataArr: MyDispDataType[] = [];
  if (selectedDetailsDisp == "details") {
    hArr.forEach((h) => {
      h.dbDataPoints.forEach((hd) => {
        dispDataArr.push({
          DbSensors: [...hd.DbSensors],
          timeDisp: `${getDateStr(hd.MyDate, false)} (${hd.hourMinute})`,
        });
      });
    });

    return dispDataArr;
  }
  let numOfHours = 1;
  if (selectedDetailsDisp == "For 3 hours") numOfHours = 3;
  else if (selectedDetailsDisp == "For 6 hours") numOfHours = 6;
  else if (selectedDetailsDisp == "For 12 hours") numOfHours = 12;
  else if (selectedDetailsDisp == "For 24 hours") numOfHours = 24;
  let dispArr: MyDispDataType[] = [];

  if (hArr.length < 1) return [];

  for (let i = 0; i < hArr.length; i += numOfHours) {
    let cnt = 0;
    for (let j = 0; j < numOfHours; j++) {
      if (hArr.length > j + i) {
        cnt++;
      }
    }
    if (cnt < 1) return [];

    let dbSensors: DBSensorType[] = [];
    sensors.forEach(sensor => {
      let sensorDB: DBSensorType = {
        name: sensor.name,
        zone: sensor.zone,
        decimal: sensor.decimal,
        value: 0,
      }
      dbSensors.push(sensorDB)
    })


    for (let j = 0; j < dbSensors.length; j++) {
      dbSensors[j].value = 0;
      for (let k = 0; k < cnt; k++) {
        dbSensors[j].value += hArr[i + k].dbSensors[j].value;
      }
      dbSensors[j].value = dbSensors[j].value / cnt;
    }

    let timeDisp: string = "";
    if (cnt == 1) {
      let d1: MyDateType = hArr[i].date;
      timeDisp = `${d1.D}/${d1.M}/${d1.Y} (${d1.H})`;
    } else {
      let d1: MyDateType = hArr[i].date;
      let d2: MyDateType = hArr[i + cnt - 1].date;

      if (d1.D == d2.D)
        timeDisp = `${d1.D}/${d1.M}/${d1.Y} (${d1.H} - ${d2.H})`;
      else
        timeDisp = `${d1.D}/${d1.M}/${d1.Y} (${d1.H}) - ${d2.D}/${d2.M}/${d2.Y} (${d2.H}) `;
    }

    dispArr.push({ DbSensors: [...dbSensors], timeDisp: timeDisp });
  }

  console.log('hArr', hArr);

  return dispArr;
}
//========================================================================================================================

interface MyDetailsDispPickerProps {
  hoursData: HourDataType[];
  setDispData: (d: MyDispDataType[]) => void;
}
//------------------------------------------------------------------------------------------------------------------------
export function MyDetailsDispPicker({
  hoursData,
  setDispData,
}: MyDetailsDispPickerProps) {
  const [selectedDetailsDisp, setSelectedDetailsDisp] = useState<
    string | undefined
  >("hourly");

  useEffect(() => {
    if (!selectedDetailsDisp) return;
    let dispDataArr: MyDispDataType[] = getDispData(hoursData, selectedDetailsDisp);


    // if (selectedDetailsDisp == "details") {
    //   hoursData.forEach((h) => {
    //     h.data.forEach((hd) => {
    //       dispDataArr.push({
    //         DbSensors: [...hd.DbSensors],
    //         timeDisp: `${getDateStr(hd.MyDate, false)} (${hd.hourMinute})`,
    //       });
    //     });
    //   });
    // } else if (selectedDetailsDisp == "hourly") {
    //   dispDataArr = getDispData(hoursData, 1);
    // } else if (selectedDetailsDisp == "For 3 hours") {
    //   dispDataArr = getDispData(hoursData, 3);
    // } else if (selectedDetailsDisp == "For 6 hours") {
    //   dispDataArr = getDispData(hoursData, 6);
    // } else if (selectedDetailsDisp == "For 12 hours") {
    //   dispDataArr = getDispData(hoursData, 12);
    // } else if (selectedDetailsDisp == "For 24 hours") {
    //   dispDataArr = getDispData(hoursData, 24);
    // }

    setDispData([...dispDataArr]);
  }, [selectedDetailsDisp, hoursData]);

  return (
    <div
      style={{
        margin: "5px",
        padding: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgb(65, 169, 255)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <Select
        style={{
          margin: "10px",
          width: "100%",
        }}
        value={selectedDetailsDisp ? selectedDetailsDisp : ""}
        onChange={(e) => {
          setSelectedDetailsDisp(e.target.value);
        }}
      >
        {detailsDispArr.map((d) => (
          <MenuItem key={d} value={d}>
            <b>{d}</b>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
//========================================================================================================================
interface MyTimePickerProps {
  allData: DBDataType[];
  onChange: (d: HourDataType[]) => void;
}
//------------------------------------------------------------------------------------------------------------------------
export function MyTimePicker({ allData, onChange }: MyTimePickerProps) {
  let _date = new Date(Date.now());
  let todayDate: MyDateType = {
    D: _date.getDate(),
    M: _date.getMonth() + 1,
    Y: _date.getFullYear(),
    H: _date.getHours(),
    m: 0,
  };
  const [selctedTime, setSelectedTime] = useState<string | undefined>("Today");
  const [d1, setD1] = useState<MyDateType | undefined>(todayDate);
  const [d2, setD2] = useState<MyDateType | undefined>(todayDate);
  useEffect(() => {
    if (d1 && d2) onChange([...getHourData(d1, d2, allData)]);
  }, [d1, d2]);

  return (
    <div
      style={{
        margin: "5px",
        padding: "5px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgb(65, 169, 255)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <Select
        style={{
          margin: "10px",
          width: "100%",
        }}
        value={selctedTime ? selctedTime : ""}
        onChange={(e) => {
          let newTime = e.target.value;
          setSelectedTime(newTime);
          if (newTime == "Today") {
            let d1 = { ...todayDate };
            d1.H = 0;
            setD1({ ...d1 });
            setD2({ ...todayDate });
          } else if (newTime == "Yesterday") {
            let d1: MyDateType = getPrevDate(todayDate, 1);
            let d2: MyDateType = { ...d1 };
            d1.H = 0;
            d2.H = 23;
            setD1(d1);
            setD2(d2);
          } else if (newTime == "Last 7 Days") {
            let d1: MyDateType = getPrevDate(todayDate, 7);
            d1.H = 0;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Last 30 Days") {
            let d1: MyDateType = getPrevDate(todayDate, 30);
            d1.H = 0;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "This Month") {
            let d1: MyDateType = {
              D: 1,
              M: todayDate.M,
              Y: todayDate.Y,
              H: 0,
              m: 0,
            };
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "This Year") {
            let d1: MyDateType = { D: 1, M: 1, Y: todayDate.Y, H: 0, m: 0 };
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Last 24 hours") {
            let d1: MyDateType = getPrevDate(todayDate, 1);
            d1.H = todayDate.H;
            setD1(d1);
            setD2({ ...todayDate });
          } else if (newTime == "Custom") {
            setD1(undefined);
            setD2(undefined);
          }
        }}
      >
        {timeDataArr.map((d) => (
          <MenuItem key={d} value={d}>
            <b>{d}</b>
          </MenuItem>
        ))}
      </Select>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue: Dayjs | null) => {
              if (!newValue) setD1(undefined);
              else
                setD1({
                  D: newValue.date(),
                  M: newValue.month() + 1,
                  Y: newValue.year(),
                  H: 0,
                  m: 0,
                });
            }}
            value={d1 ? dayjs(`${d1.Y}-${d1.M}-${d1.D}`) : dayjs(``)}
            disabled={selctedTime ? selctedTime != "Custom" : false}
          />
        </LocalizationProvider>
        <span>-</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue: Dayjs | null) => {
              if (!newValue) setD2(undefined);
              else
                setD2({
                  D: newValue.date(),
                  M: newValue.month() + 1,
                  Y: newValue.year(),
                  H: 0,
                  m: 0,
                });
            }}
            value={d2 ? dayjs(`${d2.Y}-${d2.M}-${d2.D}`) : dayjs(``)}
            disabled={selctedTime ? selctedTime != "Custom" : false}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
//========================================================================================================================
interface MyDBControlBoardProps {
  allData: DBDataType[];
  dispData: MyDispDataType[];
  setDispData: (d: MyDispDataType[]) => void;
  onSensorsChange: (s: string[]) => void;
  onTypeDispChagnge: (typeDisp: string) => void;
  selectedZones: ZoneType[];
  setSelectedZones: (z: ZoneType[]) => void;
  allZones: ZoneType[]

}
//------------------------------------------------------------------------------------------------------------------------
export function MyDBControlBoard({
  allData,
  dispData,
  setDispData,
  onSensorsChange,
  onTypeDispChagnge,
  selectedZones,
  setSelectedZones,
  allZones
}: MyDBControlBoardProps) {

  let sensorNamesList: string[] = [...getListOfDataArray("name", sensors)];

  const [selectedSensors, setSelectedSensors] = useState<string[]>(sensorNamesList);
  const [selectedTypeDisp, setSelectedTypeDisp] = useState<string>("table");
  const [hoursData, setHoursData] = useState<HourDataType[]>([]);


  useEffect(() => {
    let _date = new Date(Date.now());
    let todayDate1: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 0,
      m: 0,
    };
    let todayDate2: MyDateType = {
      D: _date.getDate(),
      M: _date.getMonth() + 1,
      Y: _date.getFullYear(),
      H: 23,
      m: 0,
    };
    setHoursData(getHourData(todayDate1, todayDate2, allData));
  }, [allData]);
  const selectedStyle = {
    color: "rgb(2, 10, 117)",
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div
      style={{
        borderColor: "rgb(65, 169, 255)",
        display: "flex",
        flexDirection: "column",
        minWidth: '300px',
        maxWidth: '300px',
      }}
    >

      <MyTimePicker
        allData={allData}
        onChange={(d) => {
          if (d.length == 0) {
            setDispData([]);
          }
          setHoursData([...d]);
        }}
      />

      {hoursData.length > 0 && (
        <MyDetailsDispPicker
          hoursData={hoursData}
          setDispData={(d: MyDispDataType[]) => setDispData(d)}
        />
      )}

      {hoursData.length > 0 && (
        <MyEnhancedListView
          title="Zones"
          items={[...getListOfDataArray("description", allZones)]}
          selectedItems={[...getListOfDataArray("description", selectedZones)]}
          setSelectedItems={(items) => {
            let sZones: ZoneType[] = [];
            items.forEach(item => {
              let z: ZoneType | undefined = allZones.find(z => z.description == item);
              if (z)
                sZones = [...sZones, z]

            })
            setSelectedZones(sZones)
          }}
        />
      )}

      {hoursData.length > 0 && (
        <MyEnhancedListView
          title="Sensors"
          items={sensorNamesList}
          selectedItems={selectedSensors}
          setSelectedItems={(items) => {
            setSelectedSensors([...items]);
            onSensorsChange(items);
          }}
        />
      )}

      {hoursData.length > 0 && (
        <MyListView
          title="Display"
          items={typeDisplay}
          selectedItem={selectedTypeDisp}
          setSelectedItem={(item) => {
            if (item) {
              setSelectedTypeDisp(item);
              onTypeDispChagnge(item);
            }
          }}
        />
      )}
      {hoursData.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={excelImg}
            width={40}
            alt="save to excel"
            onClick={() => { sendToExcel(dispData, selectedZones, selectedSensors) }}
          />
        </div>
      )}

    </div>
  );
}
//========================================================================================================================
interface MyMainDBPageProps {
  allData: DBDataType[];
  zones: ZoneType[]
}
//------------------------------------------------------------------------------------------------------------------------
export function MyMainDBPage({ allData, zones }: MyMainDBPageProps) {
  const [dispData, setDispData] = useState<MyDispDataType[]>([]);
  const [selectedZones, setSelectedZones] = useState<ZoneType[]>(zones);
  const [selectedSensorNames, setSelectedSensorNames] = useState<string[]>(
    getListOfDataArray("name", sensors)
  );
  const [selectedTypeDisp, setSelectedTypeDisp] = useState<string>("table");


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <MyDBControlBoard
        allData={allData}
        dispData={dispData}
        setDispData={setDispData}
        allZones={zones}
        selectedZones={selectedZones}
        setSelectedZones={setSelectedZones}
        onSensorsChange={(sensors) => {
          setSelectedSensorNames([...sensors]);
        }}
        onTypeDispChagnge={(dispType) => {
          setSelectedTypeDisp(dispType);
        }}
      />
      <MyDataDisp
        dispData={dispData}
        selectedZones={selectedZones}
        selectedSensorNames={selectedSensorNames}
        selectedTypeDisp={selectedTypeDisp}
      />
    </div>
  );
}
//========================================================================================================================
