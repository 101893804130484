import { SensorType, ZoneType } from "../dataTypes/myDataTypes";
import { sensors } from "../configs/Tags";
import _ from "lodash";
import { SensorListDiv } from "./SensorsDiv";
import "../index.css";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import serviceData from "../services/dataService";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import FontDownloadTwoToneIcon from "@mui/icons-material/FontDownloadTwoTone";
//=================================================================================================================
interface ZoneDivProps {
  zone: ZoneType;
  dispGraphics: boolean;
}
//-----------------------------------------------------------------------------------------------------------------
export function ZoneDiv({ zone, dispGraphics }: ZoneDivProps) {
  let mySensors = sensors.filter((s) => s.zone == zone.name);
  return (
    <div
      style={{
        borderStyle: "solid",
        borderWidth: "3px",
        borderColor: "rgb(65, 169, 255)",
        borderRadius: "20px",
        maxWidth: "1000px",
        minWidth: "200px",
      }}
    >
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <b
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {zone.name}
        </b>
        <span>{zone.description}</span>
      </div>
      <SensorListDiv
        sensors={mySensors}
        dispGraphics={dispGraphics}
        displayZoneName={false}
        displaySensorName={true}
      />
    </div>
  );
}
//=================================================================================================================
interface ZonesDivProps {
  zones: ZoneType[];
}
//-----------------------------------------------------------------------------------------------------------------
export function ZonesDiv({ zones }: ZonesDivProps) {
  const [dispGraphics, setDispGraphics] = useState(false);
  return (
    <>
      <div>
        <IconButton
          onClick={() => {
            setDispGraphics(!dispGraphics);
          }}
          color="primary"
        >
          {dispGraphics ? <SpeedTwoToneIcon /> : <FontDownloadTwoToneIcon />}
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
          padding: 10,
        }}
      >
        {zones.map((z) => (
          <ZoneDiv zone={z} key={z.id} dispGraphics={dispGraphics} />
        ))}
      </div>
    </>
  );
}
//=================================================================================================================
interface ZoneEditDivProps {
  zone: ZoneType;
}
//-----------------------------------------------------------------------------------------------------------------
export function ZoneEditDiv({ zone }: ZoneEditDivProps) {
  const [description, setDescription] = useState(zone.description);
  return (
    <div
    style={{
      background: "rgba(0, 140, 255, 0.68)",
      color: "white",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      maxHeight: "70%",
      // width: "300px",
      margin: 10,
      padding: "1px 15px",
      border: "#ced8ff",
      borderRadius: "20px",
    }}
    >
      <span style={{ margin: 4 }}>
        <b>{zone.name}</b>
      </span>

      <TextField
        style={{ margin: 4 }}
        value={description}
        multiline
        fullWidth
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />

      <IconButton
        style={{
          background: "rgb(255, 255, 255)",
          color: "rgb(65, 169, 255)",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "row",
          maxHeight: "70%",
          // width: "300px",
          margin: 10,
          padding: "1px 15px",
          border: "#ced8ff",
          borderRadius: "20px",
        }}
        onClick={async () => {
          serviceData.updateZone(zone.name, description);
        }}
      >
        Save
      </IconButton>
    </div>
  );
}
//=================================================================================================================
interface EditZonesPageProps {
  zones: ZoneType[];
}
//-----------------------------------------------------------------------------------------------------------------
export function EditZonesPage({ zones }: EditZonesPageProps) {
  return (
    <div
    // style={{
    //   display: "flex",
    //   flexWrap: "wrap",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   gap: 20,
    //   padding: 10,
    // }}
    >
      {zones.map((z) => (
        <ZoneEditDiv zone={z} key={z.id} />
      ))}
    </div>
  );
}
//=================================================================================================================
