import { useEffect, useState } from "react";
import { DBDataType, UserType, ZoneType } from "../dataTypes/myDataTypes";
import serviceData from "../services/dataService";
import MyNavBar from "./navbar/navbar2";
import { SensorListDiv } from "./SensorsDiv";
import { sensors } from "../configs/Tags";
import { MyMainDBPage } from "./DataBase/mainDBPage";
import { getSensorValue } from "./DataBase/dataUtils";
import { ManagementPage } from "./management";
import { ZonesDiv } from "./zones";
interface MyMainPageProps {
  user: UserType;
  allData: DBDataType[];
  onDeleteData: () => void;
}
//==================================================
export function MyMainPage({ user, allData, onDeleteData }: MyMainPageProps) {

  const [devDataArr, setDevDataArr] = useState<string[]>([]);
  const [PageNumber, setPageNumber] = useState(3);
  const [zones, setZones] = useState<ZoneType[]>([]);
  const current_theme = localStorage.getItem("current_theme");
  const [theme, setTheme] = useState(current_theme ? current_theme : "light");
  async function update() {
    const d: any[] = await serviceData.readDevData();
    if (d) {
      setDevDataArr([...d]);
      sensors.forEach(sensor => {
        sensor.value = getSensorValue(sensor, d);
      })
    }


  }

  async function updateZones() {
    const d: any = await serviceData.getZones(user.companyname);
    setZones(d);
  }

  useEffect(() => {
    localStorage.setItem("current_theme", theme);
  }, [theme]);
  useEffect(() => {
    const interval = setInterval(() => {
      update();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateZones();
  }, []);
  return (
    <div className="App">
      <div className={`container ${theme}`}>

        <MyNavBar
          pageNumber={PageNumber}
          setPageNumber={setPageNumber}
          theme={theme}
          setTheme={setTheme}
          user={user}
          devDataArr={devDataArr}

        />

        <div className={`layout ${theme}`}>
          <div className={`data ${theme}`}>
            {PageNumber == 2 && (
              <SensorListDiv sensors={sensors} displayZoneName={true} displaySensorName={true} dispGraphics={true} />
            )}
            {((PageNumber == 3)) &&
              <ZonesDiv zones={zones} />

            }
            {PageNumber == 7 && (
              <ManagementPage user={user} zones={zones} allData={allData} onDeleteData={onDeleteData} />
              // <EditZonesPage zones={zones}/>
            )}
            {PageNumber == 0 && (
              <MyMainDBPage allData={allData} zones={zones} />
            )}


          </div>
        </div>
      </div>
    </div>
  );

}

