import axios from 'axios'
import settings from '../configs/setings.json'
import { DBDataType, DBUserType, UserType } from '../dataTypes/myDataTypes';
import _ from 'lodash';
//=================================================
function updateItem(item: any, tableName: string): string {
    let SQL = `UPDATE ${tableName} SET `;

    const toSaveObj = _.omit(item, ['id'])
    const numOfKeys = Object.keys(toSaveObj).length;

    for (let i = 0; i < numOfKeys; i++) {
        SQL += ` ${Object.keys(toSaveObj)[i]} = '${item[Object.keys(toSaveObj)[i]]}' `;
        if (i < numOfKeys - 1) SQL += " , ";
    }

    SQL += ` WHERE ${tableName}.id = ${item.id} ;`;
    return SQL;
}
//=================================================
// function deleteItem(item:any,tableName:string):string {
//     const sql = `DELETE FROM ${tableName} WHERE id = '${item.id}'`;

//     await $.post(serviceURL, { REQACTION: "DELETESQL", SQL: sql }).then(function (
//       data,
//       status
//     ) {
//       toast(`${status}, ${data} `);
//     });
// }
//=================================================



async function readDevData() {
    try {
        const res: any = await axios.post(settings.url, { DEVNAME: settings.devName });
        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function readDBData() {
    try {
        const res = await axios.post(settings.dburl, { REQACTION: "GETSQLARR", SQL: "SELECT * FROM faiomai" }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function getZones(companyName: string) {
    try {
        let sql = `SELECT * FROM zones where companyName = '${companyName}'`;
        const res = await axios.post(settings.dburl, { REQACTION: "GETSQLARR", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function getUsers(userId: string) {
    try {
        let sql = `SELECT * FROM users where creatorId = '${userId}'`;
        const res = await axios.post(settings.dburl, { REQACTION: "GETSQLARR", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function addUser(user: DBUserType) {
    try {

        const newObj = {
            REQACTION: "ADDDATA",
            TABLENAME: 'users',
            ...user,
        };


        const res = await axios.post(settings.dburl, newObj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        console.log('res', res);

        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}

//=================================================

async function updateZone(zoneName: string, zoneDescription: string) {
    try {
        let sql = `UPDATE \`zones\` SET \`description\` = '${zoneDescription}'  WHERE \`zones\`.\`name\`= '${zoneName}' `;


        const res = await axios.post(settings.dburl, { REQACTION: "UPDATESQL", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function updateUser(user: DBUserType) {
    try {
        let sql = updateItem(user, 'users')
        const res = await axios.post(settings.dburl, { REQACTION: "UPDATESQL", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function deleteUser(user: DBUserType) {
    try {

        const sql = `DELETE FROM users WHERE id = '${user.id}'`;

        const res = await axios.post(settings.dburl, { REQACTION: "DELETESQL", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

        return res.data;
    } catch (error) {
        console.log('Error', error);
    }
}
//=================================================
async function deleteData(user: UserType, data: DBDataType[]) {
    let values: string = '';
    let deleteResult = '';

    for (let i = 0; i < data.length; i++) {
        values += data[i].id;
        if (i < data.length - 1)
            values += ','
    }
    try {

        const sql = `DELETE FROM faiomai WHERE id IN (${values})`;
        const res = await axios.post(settings.dburl, { REQACTION: "DELETESQL", SQL: sql }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })


        deleteResult = res.data;
    } catch (error) {
        console.log('Error', error);
    }


    let activity = { userId: user.id, activity: `Delete ${values}` }
    try {
        const newObj = {
            REQACTION: "ADDDATA",
            TABLENAME: 'activities',
            ...activity
        };


        const res = await axios.post(settings.dburl, newObj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

    } catch (error) {
        console.log(error);

    }
    return deleteResult;

    // 


}
//=================================================

export default {
    readDevData,
    readDBData,
    getZones,
    updateZone,
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    deleteData,

};
//=================================================