import axios from 'axios'
import settings from '../configs/setings.json'
import { UserType } from '../dataTypes/myDataTypes';
//=================================================
async function loginUser(username: string, password: string) {
    try {
        const res = await axios.post(settings.dburl, { REQACTION: "LOGIN", USER: { username: username, password: password } }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        let user = res.data;
        if (user.id) {
            localStorage.setItem('userId', user.id)
            localStorage.setItem('username', user.username)
            localStorage.setItem('authorization', user.authorization)
            localStorage.setItem('companyname', user.companyname)
            localStorage.setItem('creatorId', user.creatorId)

            return true;
        }
        logout();
        return false;


    } catch (error) {

        return false;
    }


}
//=================================================
function logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('authorization');
    localStorage.removeItem('companyname');
    localStorage.removeItem('creatorId');

    window.location.href = '/'
}
//=================================================
function getUser() {
    let id = localStorage.getItem('userId')
    let username = localStorage.getItem('username')
    let authorization = localStorage.getItem('authorization')
    let companyname = localStorage.getItem('companyname')
    let creatorId = localStorage.getItem('creatorId')
    console.log('creatorId', creatorId);

    if ((!id) || (!username) || (!authorization) || (!companyname) || (!creatorId))
        return undefined;
    else {
        let user: UserType = { id: id, username: username, authorization: authorization, companyname: companyname, creatorId: creatorId };
        return user;


    }

}
//=================================================

export default {

    loginUser,
    logout,
    getUser,
};
//=================================================